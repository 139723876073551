import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CustomColorButton from "./Button";
import IconButton from "@mui/material/IconButton";
import XIcon from "@mui/icons-material/X";
import TelegramIcon from "@mui/icons-material/Telegram";

const AppBarComponent = () => {
  return (
    <AppBar
      position="sticky"
      style={{ top: "auto", bottom: 0, zIndex: 1000, background: "#f4acba" }}
    >
      <Toolbar style={{ justifyContent: "space-between" }} color="f4acba">
        <div>
          <IconButton
            aria-label="twitter"
            href="https://twitter.com/smoothonsol"
            target="_blank"
          >
            <XIcon />
          </IconButton>
          <IconButton
            aria-label="telegram"
            href="https://t.me/+QTNrD2frtVozNjk1"
            target="_blank"
          >
            <TelegramIcon />
          </IconButton>
        </div>
        <CustomColorButton
          text="BUY $SMOOTH"
          hyperlink="http://raydium.io/swap/?inputCurrency=sol&outputCurrency=9f7T9awNh8FWJY7cYf5jC29BJdRu8wDUtWsykhDLPLUG&fixed=in"
        />
      </Toolbar>
    </AppBar>
  );
};
// TODO: update BUY LINK
export default AppBarComponent;
