import "../App.css";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import React, { useEffect, useRef } from "react";

const TitleSection = () => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const updateImageHeight = () => {
      if (containerRef.current) {
        const containerHeight = containerRef.current.clientHeight;
        const imageElement = containerRef.current.querySelector(
          ".title-image"
        ) as HTMLImageElement;

        if (imageElement) {
          const aspectRatio = 1225 / 988;
          // Adjust the factor as needed (e.g., 0.9 for 90%)
          const targetHeight = containerHeight * 0.8;
          const targetWidth = targetHeight * aspectRatio;
          imageElement.style.height = `${targetHeight}px`;
          imageElement.style.width = `${targetWidth}px`;
        }
      }
    };

    window.addEventListener("resize", updateImageHeight);
    updateImageHeight(); // Set initial height

    return () => {
      window.removeEventListener("resize", updateImageHeight);
    };
  }, []);

  return (
    // <div className="main-container">ohello hello</div>
    <header className="App-header">
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        {/* <Grid item xs={12} sm={5} md={5} ref={containerRef} className="title-grid-left">
          <img
            className="title-image"
            src="/assets/smoothbrain-mascot.png"
            alt="smooth-brain"
          />
        </Grid> */}
        {/* <Grid item xs={12} sm={7} md={7} className="tokenomics-container"> */}
        <Grid item xs={12} sm={12} md={12} className="tokenomics-container">
          <Grid container spacing={2}>
            {/* <Grid item xs={12} className="title-grid-right"> */}
            <Grid item xs={12}>
              <Typography
                variant="h1"
                gutterBottom
                sx={{ fontFamily: "ComicSansMS" }}
              >
                Smooth Brain
              </Typography>
            </Grid>
            {/* <Grid item xs={12} className="title-grid-right"> */}
            <Grid item xs={12}>
              <Typography
                variant="h3"
                gutterBottom
                sx={{ fontFamily: "ComicSansMS" }}
              >
                No Lumps, No Dumps
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </header>
  );
};

export default TitleSection;
