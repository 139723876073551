import "../App.css";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import BalanceIcon from "@mui/icons-material/Balance";
import GroupRemoveIcon from "@mui/icons-material/GroupRemove";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";

const TokenomicsSection = () => {
  return (
    <div className="tokenomics-container">
      <Grid container spacing={2}>
        <Grid item md={12}>
          <Typography
            variant="h4"
            gutterBottom
            sx={{ fontFamily: "ComicSansMS" }}
          >
            TOKENOMICS
          </Typography>
        </Grid>
        <Grid item md={12}>
          <List sx={{ width: "100%" }} component="nav">
            <ListItem>
              <ListItemIcon>
                <BalanceIcon sx={{ color: "pink" }} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ fontFamily: "ComicSansMS" }}
                  >
                    1,000,000,000 Token Supply
                  </Typography>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <LocalFireDepartmentIcon sx={{ color: "pink" }} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ fontFamily: "ComicSansMS" }}
                  >
                    Burnt Liquidity
                  </Typography>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <GroupRemoveIcon sx={{ color: "pink" }} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ fontFamily: "ComicSansMS" }}
                  >
                    Revoked Mint/Freeze Authority
                  </Typography>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <MoneyOffIcon sx={{ color: "pink" }} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ fontFamily: "ComicSansMS" }}
                  >
                    No Tax
                  </Typography>
                }
              />
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </div>
  );
};

export default TokenomicsSection;
