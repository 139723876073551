import "../App.css";
import { useTheme, useMediaQuery, Typography, IconButton } from "@mui/material";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";

const CONTRACT_ADDRESS = "9f7T9awNh8FWJY7cYf5jC29BJdRu8wDUtWsykhDLPLUG";

const ContractAddress = () => {
  const theme = useTheme();
  const isSM = useMediaQuery(theme.breakpoints.down("sm"));
  const isMD = useMediaQuery(theme.breakpoints.down("md"));

  return (
    // <div className="main-container">ohello hello</div>
    <header className="contract-address">
      <Typography
        variant={isSM ? "body1" : isMD ? "h6" : "h4"}
        gutterBottom
        sx={{ fontFamily: "ComicSansMS" }}
      >
        Contract: {CONTRACT_ADDRESS}
        <IconButton
          aria-label="copy-contract-address"
          onClick={() => {
            navigator.clipboard.writeText(CONTRACT_ADDRESS);
          }}
        >
          <ContentPasteIcon fontSize="large" color="warning" />
        </IconButton>
      </Typography>
    </header>
  );
};

export default ContractAddress;
