import "../App.css";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import BalanceIcon from "@mui/icons-material/Balance";
import GroupRemoveIcon from "@mui/icons-material/GroupRemove";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import Link from "@mui/material/Link";

interface CustomLinkProps {
  text: string;
  hyperlink: string;
}

const LinkComponent: React.FC<CustomLinkProps> = ({ text, hyperlink }) => {
  return (
    <Link
      variant="h6"
      gutterBottom
      sx={{ fontFamily: "ComicSansMS", fontPalette: "white" }}
      href={hyperlink}
      target="_blank"
      color="#ffffff"
    >
      {text}
    </Link>
  );
};

const UsefulLinksSection = () => {
  return (
    <div className="tokenomics-container">
      <Grid container spacing={2}>
        <Grid item md={12}>
          <Typography
            variant="h4"
            gutterBottom
            sx={{ fontFamily: "ComicSansMS" }}
          >
            USEFUL LINKS
          </Typography>
        </Grid>
        <Grid item md={12}>
          <List sx={{ width: "100%" }} component="nav">
            <ListItem>
              <ListItemText
                primary={
                  <LinkComponent
                    text="Buy on BONKbot"
                    hyperlink="https://t.me/bonkbot_bot?start=ref_b7wha_ca_9f7T9awNh8FWJY7cYf5jC29BJdRu8wDUtWsykhDLPLUG"
                  />
                }
                style={{ textAlign: "center" }}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <LinkComponent
                    text="Buy on Unibot"
                    hyperlink="https://t.me/solana_unibot?start=r-0xmicropl857928-9f7T9awNh8FWJY7cYf5jC29BJdRu8wDUtWsykhDLPLUG"
                  />
                }
                style={{ textAlign: "center" }}
              />
            </ListItem>
            {/* <ListItem>
              <ListItemText
                primary={
                  <LinkComponent
                    text="Buy on BONKbot"
                    hyperlink="https://example.com"
                  />
                }
                style={{ textAlign: "center" }}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <LinkComponent
                    text="Buy on BONKbot"
                    hyperlink="https://example.com"
                  />
                }
                style={{ textAlign: "center" }}
              />
            </ListItem> */}
          </List>
        </Grid>
      </Grid>
    </div>
  );
};

export default UsefulLinksSection;
