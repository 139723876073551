import "./App.css";
import SmoothBrainGif from "./components/SmoothBrainGif";
import AppBarComponent from "./components/AppBar";
import MemeSection from "./components/Memes";
import TokenomicsSection from "./components/Tokenomics";
import TitleSection from "./components/Title";
import { Grid } from "@mui/material";
import UsefulLinksSection from "./components/Links";
import ContractAddress from "./components/ContractAddress";

function App() {
  return (
    <div className="App">
      <div>
        <SmoothBrainGif />
      </div>
      <div>
        <TitleSection />
      </div>
      <div className="main-container">
        <Grid container spacing={2}>
          <Grid item md={12}>
            {/* <MemeSection /> */}
          </Grid>
          <Grid item md={12} className="tokenomics-container">
            <TokenomicsSection />
          </Grid>
          <Grid item md={12} className="tokenomics-container">
            <UsefulLinksSection />
          </Grid>
          <Grid item md={12}>
            <ContractAddress />
          </Grid>
        </Grid>
      </div>

      <AppBarComponent />
    </div>
  );
}

export default App;
