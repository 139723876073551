import * as React from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import "../App.css";

interface CustomColorButtonProps {
  text: string;
  hyperlink: string;
}

const CustomColorButton: React.FC<CustomColorButtonProps> = ({
  text,
  hyperlink,
}) => {
  const buttonStyle: React.CSSProperties = {
    backgroundColor: "#ffffff",
    color: "#000000", // Text color, adjust as needed
  };

  return (
    <Button
      variant="contained"
      style={buttonStyle}
      href={hyperlink}
      target="_blank"
    >
      <Typography gutterBottom sx={{ fontFamily: "ComicSansMS" }}>
        {text}
      </Typography>
    </Button>
  );
};

export default CustomColorButton;
