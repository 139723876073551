import "../App.css";
import React, { useEffect, useRef } from 'react';

const SmoothBrainGif = () => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const updateContainerHeight = () => {
      if (containerRef.current) {
        const aspectRatio = 16 / 9; // Adjust this ratio based on your image's aspect ratio
        const containerWidth = containerRef.current.offsetWidth;
        const containerHeight = containerWidth / aspectRatio;
        containerRef.current.style.height = `${containerHeight}px`;
      }
    };

    window.addEventListener('resize', updateContainerHeight);
    updateContainerHeight(); // Set initial height

    return () => {
      window.removeEventListener('resize', updateContainerHeight);
    };
  }, []);

  return (
    <div ref={containerRef} className="gif-container">
      <img
        className="gif-image"
        src="/assets/smooth-brain-evolve.gif" // Replace with the actual path to your GIF image
        alt="smooth-brain"
      />
    </div>
  );
};

export default SmoothBrainGif;
